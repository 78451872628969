.carousel-status {
  display: none;
}

.carousel .control-arrow, .carousel.carousel-slider .control-arrow {
  opacity: 1;
  filter: alpha(opacity=100);
}

.carousel .control-arrow::before, .carousel.carousel-slider .control-arrow::before {
  position: absolute;
  top: 20vh !important;
}

.carousel .control-next.control-arrow::before {
  right: 7px;
}

.carousel .control-dots {
  top: calc(40vh - 40px);
  height: 10px;
}

/*
.carousel {
  position: absolute;
}

.carousel .thumbs-wrapper {
  top: 50vh;
}
*/